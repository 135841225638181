

    // NAVIGATION

(function($) {
    $.fn.menumaker = function(options) {
        var cssmenu = $(this), settings = $.extend({
            format: "dropdown",
            sticky: false
        }, options);
        return this.each(function() {
            $(this).find(".button").on('click', function(){
                $(this).toggleClass('menu-opened');
                var mainmenu = $(this).next('ul');
                if (mainmenu.hasClass('open')) {
                    mainmenu.slideToggle().removeClass('open');
                }
                else {
                    mainmenu.slideToggle().addClass('open');
                    if (settings.format === "dropdown") {
                        mainmenu.find('ul').show();
                    }
                }
            });
            cssmenu.find('li ul').parent().addClass('has-sub');
            multiTg = function() {
                cssmenu.find(".has-sub").prepend('<span class="submenu-button"></span>');
                cssmenu.find('.submenu-button').on('click', function() {
                    $(this).toggleClass('submenu-opened');
                    if ($(this).siblings('ul').hasClass('open')) {
                        $(this).siblings('ul').removeClass('open').slideToggle();
                    }
                    else {
                        $(this).siblings('ul').addClass('open').slideToggle();
                    }
                });
            };
            if (settings.format === 'multitoggle') multiTg();
            else cssmenu.addClass('dropdown');
            if (settings.sticky === true) cssmenu.css('position', 'fixed');
            resizeFix = function() {
                var mediasize = 1000;
                if ($( window ).width() > mediasize) {
                    cssmenu.find('ul').show();
                }
                if ($(window).width() <= mediasize) {
                    cssmenu.find('ul').hide().removeClass('open');
                }
            };
            resizeFix();
            return $(window).on('resize', resizeFix);
        });
    };
})(jQuery);

(function($){
    $(document).ready(function(){
        $("#cssmenu").menumaker({
            format: "multitoggle"
        });
    });
})(jQuery);


    // OWL CAROUSEL

    $(document).ready(function() {
        $('.owl-carousel').owlCarousel({
            autoplay: true,
            autoPlaySpeed: 5000,
            autoPlayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            rewindNav: true,
            dots: true,
            responsiveClass: true,
            responsive: {
                0: {
                    dots:false,
                    items: 1,
                    nav: false
                },
                600: {
                    items:3,
                    nav: false
                },
                1000: {
                    items:3,
                    nav: false,
                    loop: false,
                    dots: true,
                    margin:10
                }
            }
        })
    })


    // OWL CAROUSEL

    $(document).ready(function() {
        $('.owl-carousel-package').owlCarousel({
            autoplay: true,
            autoPlaySpeed: 5000,
            autoPlayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            rewindNav: true,
            dots: true,
            responsiveClass: true,
            responsive: {
                0: {
                    dots:false,
                    items: 1,
                    nav: false
                },
                600: {
                    items:3,
                    nav: false
                },
                1000: {
                    items:4,
                    nav: false,
                    loop: false,
                    dots: true,
                    margin:10
                }
            }
        })
    })
    // OWL CAROUSEL

    $(document).ready(function() {
        $('.owl-carousel-li').owlCarousel({
            autoplay: true,
            autoPlaySpeed: 5000,
            autoPlayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            rewindNav: true,
            dots: true,
            responsiveClass: true,
            animateIn: 'fadeIn', // add this
            animateOut: 'fadeOut', // and this
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items:1,
                    nav: false
                },
                1000: {
                    items:1,
                    nav: true,
                    loop: true,
                    dots: false,
                    margin:10,
                    navText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
                }
            }
        })
    })

    // Scroll effect

    $(window).scroll(function(){

        var scroll = $(window).scrollTop();

        if (scroll > 0 ) {
            $('header').addClass('sticky');
        }

        if (scroll <= 0 ) {
            $('header').removeClass('sticky');
        }

    });